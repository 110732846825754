import Head from '../components/Head'
import Footer from '../components/Footer'
import {
    Card, Text, Center, createStyles, rem, Title, Container,
    Space, List, NumberInput, Slider, Switch, SimpleGrid, Tabs,
    Button, Popover
} from '@mantine/core'
import { useState, useEffect } from 'react'

const useStyles = createStyles((theme) => ({
    header: {
        backgroundColor: "#d9ebf2"

    },

    link: {
        display: 'block',
        lineHeight: 1,
        padding: `${rem(8)} ${rem(12)}`,
        borderRadius: theme.radius.sm,
        textDecoration: 'none',
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
        fontSize: theme.fontSizes.sm,
        fontWeight: 500,

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],


        }
    },

    inner: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'


    },

}));

const links = [
    {
        'link': '/About',
        'label': 'About'
    },
    {
        'link': '/Support',
        'label': 'Support'
    },
    {
        'link': '/Pp',
        'label': 'Privacy'
    },
    {
        'link': '/Tos',
        'label': 'Terms of Use'
    }

]



function Pricing() {

    const { classes, cx } = useStyles()
    const [inputPrice, setInputPrice] = useState<number | "">(10)
    const [inputSold, setInputSold] = useState<number>(100)
    const [costTotal, setCostTotal] = useState<number>(1000)
    const [stripe, setStripe] = useState<boolean>(false)
    const [eventbriteCost, setEventbriteCost] = useState<number>(216)
    const [ticketTailorCost, setTicketTailorCost] = useState<number>(28)
    const [ticketTailorCosthigh, setTicketTailorCosthigh] = useState<number>(75)
    const [pivot, setPivot] = useState<number>(0)
    const fee = .12

    interface NumberInputProps {
        label: string;
        defaultValue: number;
        parser: (value: number) => number;
        formatter: (value: number) => number
    }


    const getCost = () => {

        if (typeof (inputPrice) === 'number') {
            console.log('stripe', stripe, inputSold)

            if (stripe === false) {

                setCostTotal(inputSold * fee)
                setEventbriteCost((inputPrice * inputSold * .037) + (1.79 * inputSold))
                setTicketTailorCost(Math.round(inputSold * .28))
                setTicketTailorCosthigh(Math.round(inputSold * .75))
            } else {
                setCostTotal((inputSold * fee) + (inputPrice * inputSold * .029) + .3)
                setEventbriteCost((inputPrice * inputSold * .037) + (1.79 * inputSold) + (((inputSold * inputPrice * .037) + (1.79 * inputSold)) + inputPrice + inputSold) * .029)
                setTicketTailorCost((inputSold * .28) + (inputPrice * inputSold * .029) + .3)
                setTicketTailorCosthigh((inputSold * .75) + (inputPrice * inputSold * .029) + .3)



            }

            setPivot(((eventbriteCost - costTotal) / (inputSold * inputPrice)) * 100)



        } else {
            console.log('no change to inputs')
        }
    }

    useEffect(() => {

        getCost()

    }, [inputPrice, inputSold, stripe])




    return (
        <body>
            <Head></Head>

            <Center>
                <Text sx={{ fontSize: '5rem' }}>Low cost.  No joke.</Text>
            </Center>

            <Space h='xl' />



            <Tabs defaultValue="Traditional" color='green' variant='pills'>
                <Tabs.List position="center">
                    <Tabs.Tab sx={{ fontSize: '1.5rem' }} value="Traditional">Traditional Ticketing</Tabs.Tab>
                    <Tabs.Tab sx={{ fontSize: '1.5rem' }} value="Honor">Honor System Ticketing</Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="Traditional" pt='xs'>
                    <Center>
                        <Card
                            padding='xl'
                            radius='xl'
                            withBorder
                            shadow='sm'
                            sx={{ width: '50rem' }}>
                            <Center>
                                <Text sx={{ fontSize: '2rem' }}>Lock in 12 cents per ticket</Text>
                            </Center>
                            <Center>
                                <List>
                                    <List.Item>Prices may eventually change to accomodate future usage and value
                                        proposition, but even when they do, you will always lock in the price
                                        that was current when you sell your first ticket.  Your price will
                                        always be the same!</List.Item>
                                    <List.Item>Simple, one price per ticket</List.Item>
                                    <List.Item>Automatic check-in for fans</List.Item>
                                    <List.Item>Fans can find your event on our platform</List.Item>
                                    <List.Item>Comedy Seen is built in Miami and as a way of giving back,
                                        tickets for Miami comedy shows will always be 3.05 cents per ticket
                                        (actually 3 cents, but it just feels good to say 305)
                                    </List.Item>
                                    <List.Item>Cost can be paid by fans or by the organizer</List.Item>
                                    <List.Item>Service launches in the U.S. March of 2025!</List.Item>
                                </List>
                            </Center>
                        </Card>
                    </Center>
                    <Space h='xl' />
                    <Center>
                        <Card
                            padding='xl'
                            radius='xl'
                            withBorder
                            shadow='sm'
                            sx={{ width: '50rem' }}>
                            <Center>
                                <Text sx={{ fontSize: '2rem' }}>Fee Calculator</Text>
                            </Center>
                            <Center>
                                <Text>Include typical processing fees?</Text>
                            </Center>
                            <Space h='sm' />
                            <Center>
                                <Switch
                                    checked={stripe}
                                    onChange={(event) => setStripe(event.currentTarget.checked)}
                                />
                            </Center>
                            <Space h='sm' />
                            <Center>
                                <Text sx={{ fontSize: '.75rem' }}>
                                    (we are partnered with Stripe)
                                </Text>
                            </Center>
                            <br />
                            <Text>Ticket price in $</Text>
                            <br />
                            <NumberInput
                                value={inputPrice}
                                onChange={setInputPrice}>
                            </NumberInput>
                            <br />
                            <Text>
                                Tickets Sold
                            </Text>
                            <br />
                            <Slider
                                min={0}
                                max={100}
                                step={10}
                                value={inputSold}
                                onChange={setInputSold}
                                marks={[
                                    { value: 10, label: '10' },
                                    { value: 20, label: '20' },
                                    { value: 30, label: '30' },
                                    { value: 40, label: '40' },
                                    { value: 50, label: '50' },
                                    { value: 60, label: '60' },
                                    { value: 70, label: '70' },
                                    { value: 80, label: '80' },
                                    { value: 90, label: '90' },
                                    { value: 100, label: '100' },
                                ]} />
                            <br />
                            <br />
                            <Text>
                                This is how much you will pay
                            </Text>
                            <Text sx={{ textAlign: 'right', fontSize: '1.5rem' }}>${costTotal.toFixed(2)}</Text>
                            <br />
                        </Card>
                    </Center>
                    <Space h='xl' />
                    <Center>
                        <Card
                            padding='xl'
                            radius='xl'
                            withBorder
                            shadow='sm'
                            sx={{ width: '50rem' }}>
                            <Center>
                                <Text sx={{ fontSize: '2rem' }}>Or pay more with others</Text>
                            </Center>
                            <br />
                            <SimpleGrid cols={2}>
                                <div>
                                    <Popover position='bottom' width={400}>
                                        <Popover.Target>
                                            <Button>Eventbrite</Button>
                                        </Popover.Target>
                                        <Popover.Dropdown>
                                            As of Feb 2, 2025, Eventbrite US fees are calculated as 3.7%+$1.79 per sold ticket.  Followed by a 2.9% payment processing fee off the total order
                                        </Popover.Dropdown>
                                    </Popover>
                                    <br />
                                    <br />
                                </div>
                                <div>
                                    <Text sx={{ textAlign: 'right' }}>${eventbriteCost.toFixed(2)}</Text>
                                </div>
                                <div>
                                    <Popover position='bottom' width={400}>
                                        <Popover.Target>
                                            <Button>Ticket Tailor - Pay as you sell</Button>
                                        </Popover.Target>
                                        <Popover.Dropdown>
                                            As of Feb 2, 2025, Ticket Tailor fees are $.75 per ticket + Stripe fees which are 2.9% + $.30 per ticket
                                        </Popover.Dropdown>
                                    </Popover>
                                    <br />
                                    <br />
                                </div>
                                <div>
                                    <Text sx={{ textAlign: 'right' }}>${ticketTailorCosthigh.toFixed(2)}</Text>
                                </div>
                                <div>
                                    <Popover position='bottom' width={400}>
                                        <Popover.Target>
                                            <Button>Ticket Tailor - Upfront</Button>
                                        </Popover.Target>
                                        <Popover.Dropdown>
                                            As of Feb 2, 2025, Ticket Tailor fees are $.28 per ticket assuming you bought THOUSANDS of dollars worth of tickets upfront
                                            + Stripe fees which are 2.9% + $.30 per ticket.  Go to their website and use their calculator to determine your actual cost.
                                        </Popover.Dropdown>
                                    </Popover>
                                    <br />
                                    <br />

                                </div>
                                <div>
                                    <Text sx={{ textAlign: 'right' }}>${ticketTailorCost.toFixed(2)}</Text>
                                </div>
                            </SimpleGrid>
                            <br />
                            <Center>
                                <Text sx={{ width: '50rem' }}>
                                    *Keep in mind that Ticket Tailor does not provide a means for users to
                                    discover your event like Comedy Seen or EventBrite.
                                </Text>
                            </Center>
                        </Card>
                    </Center>
                    {/*
                    <Space h='xl' />
                    <Center>
                        <Card
                            padding='xl'
                            radius='xl'
                            withBorder
                            shadow='sm'
                            sx={{ width: '50rem' }}>
                            <Center>
                                <Text sx={{ fontSize: '2rem' }}>Should you use Eventbrite?</Text>
                            </Center>
                            <br />
                            <Text>Based on your given scenario, if you believe you are getting less than <b>{pivot}%</b> of sales
                            from Eventbrite and that is important to you, then you seriously should consider Comedy Seen!
                            </Text>
                        </Card>
                    </Center>
                    */}
                </Tabs.Panel>
                <Tabs.Panel value="Honor" pt='xs'>
                    <Center>
                        <Card
                            padding='xl'
                            radius='xl'
                            withBorder
                            shadow='sm'
                            sx={{ width: '50rem' }}>
                            <Center>
                                <Text sx={{ fontSize: '2rem' }}>Free!</Text>
                            </Center>

                            <List>
                                <List.Item>Works with any mobile payment app</List.Item>
                                <List.Item>Fans are sent to the organizers payment page and then will be explicitly asked if they paid in order to be put on the organizers RSVP list</List.Item>
                                <List.Item>Validity of the RSVP is up to the honesty of the buyer</List.Item>
                                <List.Item>E-mail check-in reminder for fans</List.Item>
                                <List.Item>Fans can find your event on our platform</List.Item>
                                <List.Item>Recommended for a few small shows that you would like to not pay any fees on.  Or maybe you are soft launching a show to test demand.</List.Item>
                                <List.Item>Launches worldwide in March 2025!</List.Item>
                            </List>
                        </Card>
                    </Center>
                </Tabs.Panel>
            </Tabs>
            <Space h='xl' />


            <br />
            <br />
            <br />
            <br />


            <Footer links={links} />

        </body>


    )
}


export default Pricing